import React from "react";
import "./Shorts.css";
import Zdog from "zdog";

class Shorts extends React.Component {
  render() {
    return <canvas className="zdog-canvas-shorts" width="800" height="500" />;
  }

  componentDidMount() {
    const TAU = Zdog.TAU;
    const blue = "#073642";
    const brightBlue = "#268bd2";

    let shorts = new Zdog.Illustration({
      element: ".zdog-canvas-shorts", // set canvas with selector
      dragRotate: true,
      zoom: 2,
    });

    // left leg
    new Zdog.Shape({
      addTo: shorts,
      path: [
        { x: -30, y: -40 }, // start
        {
          bezier: [
            { x: -40, y: -30 }, // start control point
            { x: -40, y: 30 }, // end control point
            { x: -45, y: 50 }, // end point
          ],
        },
      ],
      closed: false,
      fill: false,
      color: blue,
      stroke: 70,
      translate: { x: 0, y: 0 },
    });

    // right leg
    new Zdog.Shape({
      addTo: shorts,
      path: [
        { x: 30, y: -40 }, // start
        {
          bezier: [
            { x: 40, y: -30 }, // start control point
            { x: 40, y: 30 }, // end control point
            { x: 45, y: 50 }, // end point
          ],
        },
      ],
      closed: false,
      fill: false,
      color: blue,
      stroke: 70,
      translate: { x: 0, y: 0 },
    });

    // waist
    new Zdog.Shape({
      addTo: shorts,
      path: [
        { x: -30, y: -40 },
        { x: 30, y: -40 },
        { x: 40, y: 10 },
        { x: 0, y: -40 },
        { x: -40, y: 10 },
      ],
      stroke: 70,
      color: blue,
    });

    // left pocket
    new Zdog.Shape({
      addTo: shorts,
      path: [
        { x: -30, y: -40 }, // start
        {
          bezier: [
            { x: -40, y: -30 }, // start control point
            { x: -40, y: 30 }, // end control point
            { x: -40, y: 0 }, // end point
          ],
        },
      ],
      closed: false,
      fill: false,
      translate: { x: -32 },
      color: brightBlue,
      stroke: 3,
    });

    // right pocket
    new Zdog.Shape({
      addTo: shorts,
      path: [
        { x: 30, y: -40 }, // start
        {
          bezier: [
            { x: 40, y: -30 }, // start control point
            { x: 40, y: 30 }, // end control point
            { x: 40, y: 0 }, // end point
          ],
        },
      ],
      closed: false,
      fill: false,
      translate: { x: 32 },
      color: brightBlue,
      stroke: 3,
    });

    function animate() {
      shorts.rotate.y += 0.01;
      shorts.updateRenderGraph();
      // animate next frame
      requestAnimationFrame(animate);
    }

    animate();
  }
}

export default Shorts;
