import React from "react";
import "./BaseballCap.css";
import Zdog from "zdog";

class Hat extends React.Component {

  render() {
    return <canvas className="zdog-canvas-hat" width="800" height="500" />;
  }

  componentDidMount() {
    const TAU = Zdog.TAU;
    const blue = "#073642";
    const dark_blue = '#002b36';
    const orange = "#cb4b16";

    let hat = new Zdog.Illustration({
      element: ".zdog-canvas-hat", // set canvas with selector
      dragRotate: true,
      rotate: { x: TAU / 7, z: TAU / 4 },
      translate: { y: -5 },
      zoom: 3.7,
    });

    var dome = new Zdog.Hemisphere({
      addTo: hat,
      diameter: 80,
      stroke: 20,
      color: orange,
      backface: dark_blue,
      fill: true,
    });

    var brim = new Zdog.Anchor({
      addTo: dome,
      scale: 45,
      translate: { y: -52, z: -6 },
      rotate: { x: TAU / 4 },
    });

    new Zdog.Shape({
      addTo: brim,
      stroke: 8,
      path: [
        { x: 0, z: 0 },
        {
          arc: [
            { x: -1, z: 0 },
            { x: -1, z: -1 },
          ],
        },
        { x: -1, z: 0 },
      ],
      color: blue,
      fill: true,
    });

    new Zdog.Shape({
      addTo: brim,
      stroke: 8,
      path: [
        { x: -1, z: 0 },
        {
          arc: [
            { x: -1, z: 1 },
            { x: 0, z: 1 },
          ],
        },
        { x: 0, z: 0 },
      ],
      color: blue,
      fill: true,
    });

    brim.copyGraph({
      scale: brim.scale.copy().multiply({ x: -1 }),
    });

    // button
    new Zdog.Shape({
      addTo: dome,
      stroke: 18,
      color: blue,
      translate: { z: 55 },
    });

    function animate() {
      hat.rotate.z += 0.01;
      hat.updateRenderGraph();
      // animate next frame
      requestAnimationFrame(animate);
    }
    animate();
  }
}

export default Hat;
