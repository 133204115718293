import React from 'react';
import Clock from 'react-live-clock';
import './DateDisplay.css';

class DateDisplay extends React.Component {

    render() {
        return (
            <span>
                <span className="date">
                    <Clock format={'dddd, MMMM D, YYYY'} ticking={true} timezone={this.props.timezone}/>
                </span>
                <span className="time">
                    <Clock format={'h:mma'} ticking={true} timezone={this.props.timezone}/>
                </span>
            </span>

        );
    }
}

export default DateDisplay;
