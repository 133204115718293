import React from "react";
import "./Gloves.css";
import Zdog from "zdog";

class Gloves extends React.Component {

  render() {
    return <canvas className="zdog-canvas2" width="800" height="500" />;
  }

  componentDidMount() {
    // eslint doesn't pick up on zDog's addTo
    const dark_blue = "#002b36";

    let glove = new Zdog.Illustration({
      element: ".zdog-canvas2",
      dragRotate: true,
      zoom: 2.3,
      translate: { y: 30 },
    });

    let palm = new Zdog.Shape({
      addTo: glove,
      name: "palm",
      path: [
        { x: -30, y: 60 }, // bottom left corner
        { x: 30, y: 60 }, // bottom right corner
        { x: 36, y: 0 },
        { x: 32, y: -40 },
        { x: -30, y: -40 },
        { x: -30, y: 60 },
      ],
      closed: false,
      fill: true,
      stroke: 20,
      color: dark_blue,
    });

    let index = new Zdog.RoundedRect({
      addTo: palm,
      name: "indexf",
      width: 1,
      height: 70,
      cornerRadius: 15,
      fill: true,
      stroke: 18,
      color: dark_blue,
      translate: { x: 34, y: -80 },
    });

    // middle
    index.copy({
      height: 90,
      translate: { x: 12, y: -80 },
    });

    // ring
    index.copy({
      height: 80,
      translate: { x: -10, y: -80 },
    });

    // pinky
    index.copy({
      height: 90,
      translate: { x: -34, y: -60 },
      rotate: { z: -0.07 },
    });

    // thumb
    new Zdog.Shape({
      addTo: palm,
      path: [
        { x: 40, y: 80 }, // start
        {
          bezier: [
            { x: 70, y: 70 }, // start control point
            { x: 60, y: 40 }, // end control point
            { x: 80, y: 20 }, // end point
          ],
        },
      ],
      closed: false,
      fill: false,
      color: dark_blue,
      stroke: 21,
      translate: { x: 0, y: -70 },
    });

    // thumb bottom
    new Zdog.Shape({
      addTo: palm,
      path: [
        { x: 40, y: 70 }, // start
        {
          bezier: [
            { x: 45, y: 50 }, // start control point
            { x: 45, y: 50 }, // end control point
            { x: 60, y: 30 }, // end point
          ],
        },
      ],
      closed: false,
      stroke: 21,
      color: dark_blue,
      translate: { x: -10, y: -25 },
    });

    // thumb top
    new Zdog.Shape({
      addTo: palm,
      path: [
        { x: 43, y: -18 }, // start
        {
          bezier: [
            { x: 50, y: 20 }, // start control point
            { x: 50, y: 20 }, // end control point
            { x: 73, y: 10 }, // end point
          ],
        },
      ],
      closed: false,
      stroke: 21,
      color: dark_blue,
      translate: { x: -10, y: -25 },
    });

    /*
    let secondGlove = palm.copyGraph({
        translate: {z: -50},
        //rotate: {z: -TAU / 19 },
        // waiting for this feature so I can give the second
        //   glove a different colour: 
        //   https://github.com/metafizzy/zdog/issues/6
        //
    });
    */

    function animate() {
      glove.rotate.y += 0.01;
      glove.updateRenderGraph();
      // animate next frame
      requestAnimationFrame(animate);
    }

    animate();
  }
}

export default Gloves;
