import React from "react";
import "./CityPicker.css";

class CityPicker extends React.Component {
  // uncontrolled component so render is not called each time a letter is typed, but only during onSubmit
  render() {
    return (
      <div className="my-funky-container">
        <form className="city-input-form" onSubmit={(e) => this.props.onSubmit(e, e.target[0].value)}>
          <input type="text" className="city-input"></input>
          <button className="city-search-button" type="submit">
            Search
          </button>
        </form>
      </div>
    );
  }
}

export default CityPicker;
