import './Title.css';

function Title() {
    return (
        <div id="container" className="Title">
            <div id="header">
                What Do I Wear: 
            </div>
        </div>
    );
}

export default Title;