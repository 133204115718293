import React from "react";
import "./LightweightJacket.css";
import Zdog from "zdog";

class LightweightJacket extends React.Component {
  render() {
    return (
      <canvas
        className="zdog-canvas-lightweight-jacket"
        width="800"
        height="500"
      />
    );
  }

  componentDidMount() {
    const TAU = Zdog.TAU;
    const yellow = "#b58900";
    const darkyellow = "#906d00";
    const violet = "#565a9c";

    let shirt = new Zdog.Illustration({
      element: ".zdog-canvas-lightweight-jacket",
      dragRotate: true,
      translate: { y: 5 },
      zoom: 1.9,
    });

    var torso = new Zdog.Shape({
      addTo: shirt,
      path: [
        { x: -40, y: 90 },
        { x: 40, y: 90 },
        { x: 50, y: -70 },
        { x: -50, y: -70 },
        { x: -40, y: 90 },
      ],
      fill: true,
      stroke: 50,
      color: yellow,
    });

    // left arm
    new Zdog.Shape({
      addTo: shirt,
      path: [
        { x: 60, y: -70 }, // left shoulder
        { x: 90, y: 20, z: -20 }, // left elbow
        { x: 90, y: 90, z: 20 }, // left hand
      ],
      fill: false,
      closed: false,
      stroke: 45,
      color: darkyellow,
    });

    // right arm
    new Zdog.Shape({
      addTo: shirt,
      path: [
        { x: -60, y: -70 }, // right shoulder
        { x: -90, y: 20, z: -20 }, // right elbow
        { x: -90, y: 90, z: 20 }, // right hand
      ],
      fill: false,
      closed: false,
      stroke: 45,
      color: darkyellow,
    });

    // hood
    new Zdog.Hemisphere({
      addTo: shirt,
      diameter: 90,
      stroke: false,
      color: yellow,
      backface: darkyellow,
      translate: { z: 0, y: -110 },
      rotate: { x: -TAU / 3 },
    });

    // zipper
    new Zdog.Rect({
      addTo: torso,
      width: 5,
      height: 170,
      stroke: 1,
      fill: true,
      translate: { y: 10, z: 25 },
      color: violet,
    });

    // left pocket
    new Zdog.Rect({
      addTo: torso,
      width: 3,
      height: 40,
      stroke: 1,
      fill: true,
      translate: { x: 35, y: 60, z: 26 },
      color: violet,
      rotate: { z: -TAU / 12 },
    });

    // right pocket
    new Zdog.Rect({
      addTo: torso,
      width: 3,
      height: 40,
      stroke: 1,
      fill: true,
      translate: { x: -35, y: 60, z: 26 },
      color: violet,
      rotate: { z: TAU / 12 },
    });

    function animate() {
      shirt.rotate.y += 0.01;
      shirt.updateRenderGraph();
      requestAnimationFrame(animate);
    }

    animate();
  }
}

export default LightweightJacket;
