import React from "react";
import "./WarmCoolToggle.css";

class WarmCoolToggle extends React.Component {
    render() {
        return (
            <div className='hot-cold-container'>
            
                <div className="switch-toggle switch-3 switch-candy">

                    <input id="off" name="state-d" type="radio" value="COOL" checked={this.props.preference === "COOL"} onChange={this.props.onChangeHandler}/>
                    <label id='COOL' htmlFor="off">COOL</label>

                    <input id="na" name="state-d" type="radio" value="NORMAL" checked={this.props.preference === "NORMAL"} onChange={this.props.onChangeHandler}/>
                    <label id='NORMAL' htmlFor="na" className="disabled">NORMAL</label>

                    <input id="on" name="state-d" type="radio" value="WARM" checked={this.props.preference === "WARM"} onChange={this.props.onChangeHandler}/>
                    <label id='WARM' htmlFor="on">WARM</label>

                </div>
            </div>
        );
    }
}

export default WarmCoolToggle;
