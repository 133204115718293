import React from "react";
import DateDisplay from "./DateDisplay";
import tzlookup from "tz-lookup";
import "./Forecast.css";
import { weatherDictionary, main as NW} from "./WeatherDictionary";
import moment from "moment";
import OpenWeatherMap from "./OpenWeatherMap/Provider.js";
import Dresser from "./Dresser";
import GpsIcon,  {GPS_STATUS} from './GpsIcon';
import City from './City';
import CityPicker from './CityPicker';
import WarmCoolToggle from './WarmCoolToggle';

const DEFAULT_LATITUDE = 43.700;
const DEFAULT_LONGITUDE = -79.416;

class Forecast extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      location: {
        latitude: DEFAULT_LATITUDE,
        longitude: DEFAULT_LONGITUDE,
        city: 'Toronto',
      },
      province_code: '',
      timezone: "America/Toronto",
      temperature: {
        value: 1.0,
        units: null,
      },
      conditions: null,
      weather: {
        main: null,
        description: null,
      },
      windspeed: {
        value: null,
        units: null,
      },
      gps_status : GPS_STATUS.NO_SIGNAL,
      city_search_string: '',
      city_search_results: [],
      temperaturePreference: 'NORMAL',
    };

    this.onSearchSubmit = this.onSearchSubmit.bind(this);
    this.onCityClick = this.onCityClick.bind(this);
    this.onWarmCoolClick = this.onWarmCoolClick.bind(this);
  }

  render() {
    return (
      <div className="Forecast">

        <div className='city-date-weather-container'> 
          <div className="city">
            {this.state.location.city} {/* TODO: List province or country. */}
            <GpsIcon status={this.state.gps_status}/>
          </div>
          <div className="date-display">
            <DateDisplay className="date-display" timezone={this.state.timezone}/>
          </div>
          <div className="weather-container">
            <div className="weather-details">
              <div className="temperature">{this.state.temperature.value.toFixed(1)}°{this.state.temperature.units}</div>
              <div className="weather">{this.state.weather.description}</div>
              <div className="wind-speed">Wind: {this.state.windspeed.value ? this.state.windspeed.value.toFixed() : this.state.windspeed.value}{this.state.windspeed.units}</div>
            </div>
              <div className="weather-icon">
                  {this.getWeatherIcon(this.state.weather)}
              </div>
          </div>

          <WarmCoolToggle preference={this.state.temperaturePreference} onChangeHandler={this.onWarmCoolClick}/>

          <CityPicker searchString={this.state.city_search_string} onSubmit={this.onSearchSubmit}/>
       
          <div className = 'city-list-container'>
            <ul className ='city-list'>
                {this.state.city_search_results.map(r => <City city_name={r.name} lat={r.lat} lon={r.lon} country_code={r.country} state_code={r.state} key={r.lat.toString() + r.lon.toString()} onClick={this.onCityClick}/>)}
            </ul>
          </div>

        </div>

        <Dresser className='dresser' temperature={this.adjustTemperatureForWeather(this.state.temperature, this.state.weather, this.state.windspeed)} isDayTime={this.isDayTime()}/>
      </div>
    );
  }

  async onWarmCoolClick(event) {
    this.setState({
      temperaturePreference: event.target.value
    });
  }

  async onSearchSubmit(event, cityInput){
    event.preventDefault(); // prevents form submission and subsequent page refresh
    document.activeElement.blur();

    this.setState({
      city_search_string: cityInput
    });

    if (cityInput === ''){
      this.setState({
        city_search_results: [],
      });
      return;
    }

    console.debug('Searching for city: ' + cityInput);
    var openWeatherMap = new OpenWeatherMap();
    var cities = await openWeatherMap.findCities(cityInput);

    this.setState({
      city_search_results: cities,
    });
  }

  onCityClick(lat, lon, name){
    this.setState({
      location: {
        latitude: lat,
        longitude: lon,
        city: name,
      },
      city_search_results: [],
    });
  }

  async componentDidMount() {
    await this.getForecast(DEFAULT_LATITUDE, DEFAULT_LONGITUDE);
    await this.findCoordinates();
  }

  // an update can be caused by changes to props or state.  Not called for the inital render.
  async componentDidUpdate(prevProps, prevState){
    // latitude or longitude updated; get forecast //and set timezone.
    if (prevState.location.latitude !== this.state.location.latitude || prevState.location.longitude !== this.state.location.longitude){
      this.findTimeZone(this.state.location.latitude, this.state.location.longitude);
      await this.getForecast(this.state.location.latitude, this.state.location.longitude);
    }

    if (prevState.temperature.value !== this.state.temperature.value || prevState.temperature.units !== this.state.temperature.units){
      document.title = "What Do I Wear at " + this.state.temperature.value.toFixed(0) + '°' + this.state.temperature.units;
    }

  }

  async getForecast(latitude, longitude){
    this.setState({
      gps_status: GPS_STATUS.SEARCHING,
    });
    var openWeatherMap = new OpenWeatherMap();
    var forecast = await openWeatherMap.getForecast(latitude, longitude);

    if (forecast.city.toLowerCase() !== this.state.location.city?.toLowerCase())
      console.warn("DEBUG: Location from search does not match forecast's location!");

    this.setState({
      location: {
        latitude: latitude, 
        longitude: longitude, 
        city: this.state.location.city ?? forecast.city}, // If they do not match, do not change it.
      temperature: forecast.temperature,
      windspeed: forecast.wind,
      sunrise: forecast.sunrise,
      sunset: forecast.sunset,
      weather: forecast.weather,
      gps_status: GPS_STATUS.LOCATION_FOUND
    });
  }

  isTwilight() {
    var currentDateTime = new Date();
    var isDusk = currentDateTime > this.getDusk(this.state.sunset);
    var isDawn  = currentDateTime < this.getDawn(this.state.sunrise);
    return isDusk || isDawn;
  }

  isDayTime(){
    var currentDateTime = new Date();
    return (currentDateTime < this.state.sunset && currentDateTime > this.state.sunrise) && !this.isTwilight();
  }

  adjustTemperatureForWeather(temperature, weather, windspeed){
    if(!weather){
      return temperature.value;
    }
  
    var temperatureAdjustment = 0;

    var nw = this.normalizeWeather(weather);
    console.log("Normalized weather: " + nw);
    
    var isTwilight = this.isTwilight();
    var isDaytime = this.isDayTime();
    var isBreezy = windspeed.value >= 20 && windspeed.value < 35;
    var isWindy = windspeed.value >= 35

    if(isDaytime && nw === NW.CLEAR && !isBreezy && !isWindy){
      temperatureAdjustment = 2;
    }
    else if (isDaytime && nw === NW.CLOUDS && !isBreezy && !isWindy){
      temperatureAdjustment = 0;
    }
    else if (isTwilight && nw === NW.CLEAR && !isBreezy && !isWindy){
      temperatureAdjustment = 1;
    }
    else if (nw === NW.RAIN){
      temperatureAdjustment = -4.0;
    }
    else if (nw === NW.DRIZZLE){
      temperatureAdjustment = -2;
    }
    else if (nw === NW.SNOW){
      temperatureAdjustment = -3;
    }
    else if (isBreezy){
      temperatureAdjustment -= 2.5;
    }
    else if (isWindy){
      temperatureAdjustment -= 4
    }
    else if (!isDaytime){
      temperatureAdjustment -= 1;
    }

    if (this.state.temperaturePreference === 'WARM'){
      console.debug(`adjusted temperature from ${temperature.value} to ${temperature.value + temperatureAdjustment} to ${temperature.value + temperatureAdjustment + 5}`)

      temperatureAdjustment -= 5;
    }
    if (this.state.temperaturePreference === 'COOL') {
      console.debug(`adjusted temperature from ${temperature.value} to ${temperature.value + temperatureAdjustment} to ${temperature.value + temperatureAdjustment - 5}`)

      temperatureAdjustment += 5;
    }

    console.debug("Temperature adjusted by: "  + temperatureAdjustment + ' to ' + (temperature.value + temperatureAdjustment));
    return temperature.value + temperatureAdjustment;
  }

  getDawn(sunriseDate){
    // civil dawn ends at sunrise
    const meanDawn = 32; // mean civil dawn time range for Toronto, in minutes
    return this.addMinutes(sunriseDate, -meanDawn);
  }

  getDusk(sunsetDate){
    // civil dusk starts at sunset
    return sunsetDate;
  }

  // return a new date object with minutes added
  addMinutes(date, minutes){;
    return moment(date).add(minutes, 'm').toDate();
  }

  normalizeWeather(conditions){
    if (conditions && conditions.main !== undefined){
      return conditions.main;
    }
    return 'UNKNOWN';
  }

  getWeatherIcon(weather){
    if (!weather || !weather.main || !weather.description){
      return;
    }

    var isDayTime = this.isDayTime();
    let entry = weatherDictionary['clear'];

    if (weather.description.toLowerCase() in weatherDictionary){
      entry = weatherDictionary[weather.description.toLowerCase()];
    } else if (weather.main.toLowerCase() in weatherDictionary){
      entry = weatherDictionary[weather.main.toLowerCase()]
    }

    return isDayTime ? entry.day_icon :  entry.night_icon;

  }

  findCoordinates = () => {
    this.setState({
      gps_status: GPS_STATUS.SEARCHING
    });
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.setState(
          {
            location: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            }
          }
        );
        console.debug('location permission granted.');
      },
      (error) => {
        console.error(error.message);
        this.setState({gps_status : GPS_STATUS.NO_SIGNAL});
      },
      { enableHighAccuracy: true}
    );
  };

  findTimeZone(latitude, longitude) {
    this.setState({
      timezone: tzlookup(latitude, longitude),
    });
  }
}

export default Forecast;