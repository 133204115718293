import React from "react";
import "./ShortSleeveShirt.css";
import Zdog from "zdog";

class ShortSleeveShirt extends React.Component {
    render() {
        return (
          <canvas
            className="zdog-canvas-short-sleeve-shirt"
            width="800"
            height="500"
          />
        );
      }

  componentDidMount() {
    const TAU = Zdog.TAU;
    const blue = "#073642";
    const brightBlue = "#268bd2";

    let shirt = new Zdog.Illustration({
      element: ".zdog-canvas-short-sleeve-shirt", // set canvas with selector
      dragRotate: true,
      /*rotate: {x: TAU/7, z: TAU/-5, y: TAU/-14},*/
      translate: { y: -10 },
      zoom: 2,
    });

    // torso
    new Zdog.Shape({
      addTo: shirt,
      path: [
        { x: -30, y: 90 },
        { x: 30, y: 90 },
        { x: 50, y: -70 },
        { x: -50, y: -70 },
        { x: -40, y: 90 },
      ],
      fill: true,
      stroke: 40,
      color: brightBlue,
    });

    // left arm
    new Zdog.Shape({
      addTo: shirt,
      path: [
        { x: 60, y: -70 }, // right shoulder
        { x: 90, y: -20, z: 0 }, // right elbow
      ],
      fill: false,
      closed: false,
      stroke: 40,
      color: brightBlue,
    });

    // right arm
    new Zdog.Shape({
      addTo: shirt,
      path: [
        { x: -60, y: -70 }, // right shoulder
        { x: -90, y: -20, z: 0 }, // right elbow
      ],
      fill: false,
      closed: false,
      stroke: 40,
      color: brightBlue,
    });

    // collar
    new Zdog.Ellipse({
      addTo: shirt,
      diameter: 55,
      quarters: 2,
      stroke: 1,
      fill: true,
      color: blue,
      translate: { y: -90, z: 10 },
      rotate: { z: TAU / 4 },
    });

    // collar negative
    new Zdog.Ellipse({
      addTo: shirt,
      diameter: 80,
      quarters: 2,
      stroke: 1,
      fill: true,
      color: brightBlue,
      translate: { y: -89, z: -5 },
      rotate: { z: TAU / 4 },
    });

    function animate() {
      shirt.rotate.y += 0.01;
      shirt.updateRenderGraph();
      // animate next frame
      requestAnimationFrame(animate);
    }

    animate();
  }
}

export default ShortSleeveShirt;
