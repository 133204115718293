import {ReactComponent as Clouds} from './assets/images/clouds.svg';
import {ReactComponent as Snow} from './assets/images/cloud-snow.svg';
import {ReactComponent as Squall} from './assets/images/snow-blowing.svg';
import {ReactComponent as Thunderstorm} from './assets/images/thunderstorm.svg';
import {ReactComponent as Sun} from './assets/images/sun.svg';
import {ReactComponent as SunCloud} from './assets/images/sun-cloud.svg';
import {ReactComponent as CloudSun} from './assets/images/cloud-sun.svg';
import {ReactComponent as CloudsSun} from './assets/images/clouds-sun.svg';
import {ReactComponent as MoonCloud} from './assets/images/moon-cloud.svg';
import {ReactComponent as CloudMoon} from './assets/images/cloud-moon.svg';
import {ReactComponent as CloudsMoon} from './assets/images/clouds-moon.svg';
import {ReactComponent as Moon} from './assets/images/moon.svg';
import {ReactComponent as Drizzle} from './assets/images/cloud-drizzle.svg';
import {ReactComponent as Rain} from './assets/images/cloud-rain.svg';
import {ReactComponent as Mist, ReactComponent as Fog} from './assets/images/fog.svg';
import {ReactComponent as Smoke} from './assets/images/smoke.svg';
import {ReactComponent as Dust, ReactComponent as Sand} from './assets/images/sun-dust.svg';
import {ReactComponent as Ash} from './assets/images/fire-smoke.svg';
import {ReactComponent as Tornado} from './assets/images/tornado.svg';
import {ReactComponent as Showers} from './assets/images/cloud-showers.svg';
import {ReactComponent as Haze} from './assets/images/sun-haze.svg';
import {ReactComponent as Volcano} from './assets/images/volcano.svg';

/*
General weather
https://openweathermap.org/weather-conditions#Weather-Condition-Codes-2
*/
export const main = {
    'THUNDERSTORM': 'thunderstorm',
    'DRIZZLE': 'drizzle',
    'RAIN': 'rain',
    'SNOW': 'snow',
    'MIST': 'mist',
    'SMOKE': 'smoke',
    'DUST': 'dust',
    'FOG': 'fog',
    'SAND' : 'sand',
    'ASH' : 'ash',
    'SQUALL' : 'squall',
    'TORNADO' : 'tornado',
    'CLOUDS' : 'clouds',
    'CLEAR' : 'clear',
}

/*
Specific weather
https://openweathermap.org/weather-conditions#Weather-Condition-Codes-2
*/
export const description = {
    // Thunderstorm
    'THUNDERSTORMLIGHTRAIN' : 'thunderstorm with light rain',
    'THUNDERSTORMRAIN' : 'thunderstorm with rain',
    'THUNDERSTORMHEAVYRAIN' : 'thunderstorm with heavy rain',
    'LIGHTTHUNDERSTORM' : 'light thunderstorm',
    'HEAVYTHUNDERSTORM' : 'heavy thunderstorm',
    'RAGGEDTHUNDERSTORM' : 'ragged thunderstorm',
    'THUNDERSTORMLIGHTDRIZZLE' : 'thunderstorm with light drizzle',
    'THUNDERSTORMDRIZZLE' : 'thunderstorm with drizzle',
    'THUNDERSTORMHEAVYDRIZZLE' : 'thunderstorm with heavy drizzle',

    // TODO: Drizzle
    'LIGHTINTENSITYDRIZZLE' : 'light intensity drizzle',
    'HEAVYINTENSITYDRIZZLE' : 'heavy intensity drizzle',
    'LIGHTINTENSITYDRIZZLERAIN' : '	light intensity drizzle rain',
    'DRIZZLERAIN' : 'drizzle rain',
    'HEAVYINTENSITYDRIZZLERAIN': 'heavy intensity drizzle rain',
    'SHOWERRAINDRIZZLE' : '	shower rain and drizzle',
    'HEAVYSHOWERRAINDRIZZLE' : 'heavy shower rain and drizzle',
    'SHOWERDRIZZLE' : 'shower drizzle',

    // TODO: Rain
    // TODO: Snow
    
    // Atmosphere
    'HAZE' : 'Haze',
    'SANDDUSTWHIRLS' : 'sand/ dust whirls',
    'VOLCANICASH' : 'volcanic ash',

    // Clouds
    'FEWCLOUDS' : 'few clouds',
    'SCATTEREDCLOUDS' : 'scattered clouds',
    'BROKENCLOUDS' : 'broken clouds',
    'OVERCASTCLOUDS' : 'overcast clouds',
}

export const weatherDictionary = {

    // main
    'thunderstorm' : {
        day_icon: <Thunderstorm alt={main.THUNDERSTORM + ' icon'}></Thunderstorm>,
        night_icon: <Thunderstorm alt={main.THUNDERSTORM + ' icon'}></Thunderstorm>,
    },
    'drizzle' : {
        day_icon: <Drizzle alt={main.DRIZZLE + ' icon'}></Drizzle>,
        night_icon: <Drizzle alt={main.DRIZZLE + ' icon'}></Drizzle>,
    },
    'rain' : {
        day_icon: <Rain alt={main.RAIN + ' icon'}></Rain>,
        night_icon: <Rain alt={main.RAIN + ' icon'}></Rain>,
    },
    'snow' : {
        day_icon: <Snow alt={main.SNOW + ' icon'}></Snow>,
        night_icon: <Snow alt={main.SNOW + ' icon'}></Snow>,
    },
    'mist' : {
        day_icon: <Mist alt={main.MIST + ' icon'}></Mist>,
        night_icon: <Mist alt={main.MIST + ' icon'}></Mist>,
    },
    'smoke' : {
        day_icon: <Smoke alt={main.SMOKE + ' icon'}></Smoke>,
        night_icon: <Smoke alt={main.SMOKE + ' icon'}></Smoke>,
    },
    'dust' : {
        day_icon: <Dust alt={main.DUST + ' icon'}></Dust>,
        night_icon: <Dust alt={main.DUST + ' icon'}></Dust>,
    },
    'fog' : {
        day_icon: <Fog alt={main.FOG + ' icon'}></Fog>,
        night_icon: <Fog alt={main.FOG + ' icon'}></Fog>,
    },
    'sand' : {
        day_icon: <Sand alt={main.SAND + ' icon'}></Sand>,
        night_icon: <Sand alt={main.SAND + ' icon'}></Sand>,
    },
    'ash' : {
        day_icon: <Ash alt={main.ASH + ' icon'}></Ash>,
        night_icon: <Ash alt={main.ASH + ' icon'}></Ash>,
    },
    'squall' : {
        day_icon: <Squall alt={main.SQUALL + ' icon'}></Squall>,
        night_icon: <Squall alt={main.SQUALL + ' icon'}></Squall>,
    },
    'tornado' : {
        day_icon: <Tornado alt={main.TORNADO + ' icon'}></Tornado>,
        night_icon: <Tornado alt={main.TORNADO + ' icon'}></Tornado>,
    },
    'clouds' : {
        day_icon: <Clouds alt={main.CLOUDS + ' icon'}></Clouds>,
        night_icon: <Clouds alt={main.CLOUDS + ' icon'}></Clouds>,
    },
    'clear' : {
        day_icon: <Sun alt={main.CLEAR + ' icon'}></Sun>,
        night_icon: <Moon alt={main.CLEAR + ' icon'}></Moon>,
    },

    // Drizzle
    'shower rain and drizzle' : {
        day_icon: <Showers alt={main.SHOWERRAINDRIZZLE + ' icon'}></Showers>,
        night_icon: <Showers alt={main.SHOWERRAINDRIZZLE + ' icon'}></Showers>,
    },
    'heavy shower rain and drizzle' : {
        day_icon: <Showers alt={main.HEAVYSHOWERRAINDRIZZLE + ' icon'}></Showers>,
        night_icon: <Showers alt={main.HEAVYSHOWERRAINDRIZZLE + ' icon'}></Showers>,
    },
    'shower drizzle' : {
        day_icon: <Showers alt={main.SHOWERDRIZZLE + ' icon'}></Showers>,
        night_icon: <Showers alt={main.SHOWERDRIZZLE + ' icon'}></Showers>,
    },

    // TODO: Rain
    // TODO: Snow

    // Atmosphere
    'Haze' : {
        day_icon: <Haze alt={main.HAZE + ' icon'}></Haze>,
        night_icon: <Haze alt={main.HAZE + ' icon'}></Haze>,
    },
    'SANDDUSTWHIRLS' : {
        day_icon: <Dust alt={main.SANDDUSTWHIRLS + ' icon'}></Dust>,
        night_icon: <Dust alt={main.SANDDUSTWHIRLS + ' icon'}></Dust>,
    },
    'VOLCANICASH' : {
        day_icon: <Volcano alt={main.VOLCANICASH + ' icon'}></Volcano>,
        night_icon: <Volcano alt={main.VOLCANICASH + ' icon'}></Volcano>,
    },

    // Clouds
    'few clouds' : {
        day_icon: <SunCloud alt={main.FEWCLOUDS + ' icon'}></SunCloud>,
        night_icon: <MoonCloud alt={main.FEWCLOUDS + ' icon'}></MoonCloud>,
    },
    'scattered clouds' : {
        day_icon: <CloudSun alt={main.SCATTEREDCLOUDS + ' icon'}></CloudSun>,
        night_icon: <CloudMoon alt={main.SCATTEREDCLOUDS + ' icon'}></CloudMoon>,
    },
    'broken clouds' : {
        day_icon: <CloudsSun alt={main.BROKENCLOUDS + ' icon'}></CloudsSun>,
        night_icon: <CloudsMoon alt={main.BROKENCLOUDS + ' icon'}></CloudsMoon>,
    },
    'overcast clouds' : {
        day_icon: <Clouds alt={main.OVERCASTCLOUDS + ' icon'}></Clouds>,
        night_icon: <Clouds alt={main.OVERCASTCLOUDS + ' icon'}></Clouds>,
    },
};