import React from "react";
import "./Garment.css";
import BaseballCap from "./assets/3d-models/BaseballCap";
import Gloves from "./assets/3d-models/Gloves";
import Mittens from "./assets/3d-models/Mittens";
import Beanie from "./assets/3d-models/Beanie";
import HeavyBeanie from "./assets/3d-models/HeavyBeanie";
import LongSleeveShirt from "./assets/3d-models/LongSleeveShirt";
import Headband from "./assets/3d-models/Headband";
import LightweightJacket from "./assets/3d-models/LightweightJacket";
import ShortSleeveShirt from "./assets/3d-models/ShortSleeveShirt";
import Shorts from "./assets/3d-models/Shorts";



class Garment extends React.Component {
  render() {
    return (
      <li
        className="list-item"
        id={"animationOrder" + this.props.animationOrder}
      >
        <div className="flexbox-container">
          <div className="myIcon">{this.getIcon(this.props.name)}</div>
          <div className="text-div">
            <span className="text-title">{this.props.name}</span>
            <br /> {this.props.description}
          </div>
        </div>
      </li>
    );
  }

  getIcon(name) {
    switch (name) {
      case "baseball cap":
        return <BaseballCap />;
      case "lightweight beanie":
        return <Beanie />;
      case "heavyweight beanie":
        return <HeavyBeanie />;
      case "lightweight gloves":
        return <Gloves />;
      case "midweight gloves or mittens":
          return <Mittens />;
      case "long sleeve shirt":
        return <LongSleeveShirt />;
      case "short sleeve shirt":
        return <ShortSleeveShirt />;
      case "lightweight jacket":
        return <LightweightJacket />;
      case "lightweight headband":
        return <Headband />;
      case "shorts":
          return <Shorts />;
      default:
        return;
    }
  }
}

export default Garment;
