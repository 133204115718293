import React from "react";
import "./Beanie.css";
import Zdog from "zdog";

class Beanie extends React.Component {
  render() {
    return <canvas className="zdog-canvas-beanie" width="800" height="500" />;
  }

  componentDidMount() {
    const TAU = Zdog.TAU;
    const orange = "#cb4b16";
    const blue = "#073642";

    let hat = new Zdog.Illustration({
      element: ".zdog-canvas-beanie", // set canvas with selector
      dragRotate: true,
      rotate: { x: TAU / 7, z: TAU / -5, y: TAU / -14 },
      translate: { y: -6 },
      zoom: 4.1,
    });

    var dome = new Zdog.Hemisphere({
      addTo: hat,
      diameter: 80,
      stroke: 20,
      color: orange,
      backface: orange,
      fill: true,
    });

    // headband
    new Zdog.Cylinder({
      addTo: dome,
      diameter: 80,
      stroke: 25,
      length: 25,
      color: blue,
      backface: blue,
      frontface: blue,
      fill: true,
      translate: { z: -20, y: 0 },
    });

    // loop
    new Zdog.Shape({
      addTo: dome,
      path: [
        { z: 52 }, // start
        {
          bezier: [
            { z: 70, y: 15 }, // start control point
            { z: 70, y: -15 }, // end control point
            { z: 52 }, // end point
          ],
        },
      ],
      closed: false,
      stroke: 5,
      color: blue,
    });

    function animate() {
      hat.rotate.z += 0.01;
      hat.updateRenderGraph();
      // animate next frame
      requestAnimationFrame(animate);
    }

    animate();
  }
}

export default Beanie;
