import React from "react";
import "./Headband.css";
import Zdog from "zdog";

class Headband extends React.Component {
  render() {
    return <canvas className="zdog-canvas-headband" width="800" height="500" />;
  }

  componentDidMount() {
    const TAU = Zdog.TAU;
    const brightBlue = "#268bd2";

    let headband = new Zdog.Illustration({
      element: ".zdog-canvas-headband",
      dragRotate: true,
      rotate: { x: TAU / 9, z: TAU / -8, y: TAU / -20 },
      translate: { y: -18 },
      zoom: 2.6,
    });

    var outerLayer = new Zdog.Ellipse({
      addTo: headband,
      diameter: 150,
      stroke: 25,
      color: brightBlue,
    });

    outerLayer.copy({ translate: { z: -10, y: -2 } });
    outerLayer.copy({ translate: { z: -20, y: -4 } });
    outerLayer.copy({ translate: { z: -30, y: -6 } });
    outerLayer.copy({ translate: { z: -40, y: -8 } });
    outerLayer.copy({ translate: { z: -50, y: -10 } });

    function animate() {
      headband.rotate.z += 0.01;
      headband.updateRenderGraph();
      requestAnimationFrame(animate);
    }

    animate();
  }
}

export default Headband;
