import React from "react";
import "./Mittens.css";
import Zdog from "zdog";

class Mittens extends React.Component {
  render() {
    return <canvas className="zdog-mittens" width="800" height="500" />;
  }

  componentDidMount() {
    const dark_blue = "#002b36";
    const TAU = Zdog.TAU;

    let glove = new Zdog.Illustration({
      element: ".zdog-mittens",
      dragRotate: true,
      zoom: 2.1,
      translate: { y: 38 },
    });

    let palm = new Zdog.Shape({
      addTo: glove,
      name: "palm",
      path: [
        { x: -30, y: 60 }, // bottom left corner
        { x: 30, y: 60 }, // bottom right corner
        { x: 36, y: 0 },
        { x: 32, y: -40 },
        { x: -30, y: -40 },
        { x: -30, y: 60 },
      ],
      closed: false,
      fill: true,
      stroke: 23,
      color: dark_blue,
    });

    // hand
    new Zdog.Rect({
      addTo: glove,
      width: 60,
      height: 70,
      stroke: 23,
      color: dark_blue,
      fill: true,
      translate: { y: -70 },
    });

    // closure
    new Zdog.Ellipse({
      addTo: glove,
      diameter: 60,
      quarters: 2,
      stroke: 23,
      color: dark_blue,
      fill: true,
      translate: { y: -110 },
      rotate: { z: -TAU / 4, x: TAU / 20 },
    });

    // thumb
    new Zdog.Shape({
      addTo: palm,
      path: [
        { x: 40, y: 80 }, // start
        {
          bezier: [
            { x: 70, y: 70 }, // start control point
            { x: 60, y: 40 }, // end control point
            { x: 70, y: 20, z: -10 }, // end point
          ],
        },
      ],
      closed: false,
      fill: false,
      color: dark_blue,
      stroke: 23,
      translate: { x: 0, y: -70 },
    });

    // thumb bottom
    new Zdog.Shape({
      addTo: palm,
      path: [
        { x: 40, y: 70 }, // start
        {
          bezier: [
            { x: 45, y: 50 }, // start control point
            { x: 45, y: 50 }, // end control point
            { x: 60, y: 30 }, // end point
          ],
        },
      ],
      closed: false,
      stroke: 23,
      color: dark_blue,
      translate: { x: -10, y: -25 },
    });

    // thumb top
    new Zdog.Shape({
      addTo: palm,
      path: [
        { x: 42, y: -18 }, // start
        {
          bezier: [
            { x: 50, y: 20 }, // start control point
            { x: 50, y: 20 }, // end control point
            { x: 71, y: 10, z: -1 }, // end point
          ],
        },
      ],
      closed: false,
      stroke: 23,
      color: dark_blue,
      translate: { x: -10, y: -25 },
    });

    function animate() {
      glove.rotate.y += 0.01;
      glove.updateRenderGraph();
      requestAnimationFrame(animate);
    }

    animate();
  }
}

export default Mittens;
