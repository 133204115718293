import React from "react";
import "./LongSleeveShirt.css";
import Zdog from "zdog";

class LongSleeveShirt extends React.Component {
  render() {
    return (
      <canvas
        className="zdog-canvas-long-sleeve-shirt"
        width="800"
        height="500"
      />
    );
  }

  componentDidMount() {
    const TAU = Zdog.TAU;
    const blue = "#073642";
    const brightBlue = "#268bd2";

    let shirt = new Zdog.Illustration({
      element: ".zdog-canvas-long-sleeve-shirt", // set canvas with selector
      dragRotate: true,
      translate: { y: 5 },
      zoom: 2,
    });

    // torso
    new Zdog.Shape({
      addTo: shirt,
      path: [
        { x: -40, y: 90 },
        { x: 40, y: 90 },
        { x: 50, y: -70 },
        { x: -50, y: -70 },
        { x: -40, y: 90 },
      ],
      fill: true,
      stroke: 40,
      color: brightBlue,
    });

    // left arm
    new Zdog.Shape({
      addTo: shirt,
      path: [
        { x: 50, y: -70 }, // shoulder
        { x: 100, y: 20, z: -40 }, // elbow
        { x: 50, y: 70, z: 40 }, // hand
      ],
      fill: false,
      closed: false,
      stroke: 40,
      color: blue,
    });

    // right arm
    new Zdog.Shape({
      addTo: shirt,
      path: [
        { x: -60, y: -70 }, // shoulder
        { x: -100, y: 20, z: -40 }, // elbow
        { x: -50, y: 70, z: 40 }, // hand
      ],
      fill: false,
      closed: false,
      stroke: 40,
      color: blue,
    });

    var collar = new Zdog.Cylinder({
      addTo: shirt,
      diameter: 45,
      stroke: 20,
      length: 10,
      color: brightBlue,
      backface: brightBlue,
      frontface: brightBlue,
      fill: true,
      translate: { z: 20, y: -90 },
      rotate: { x: TAU / 10 },
    });

    // collar hole
    new Zdog.Ellipse({
      addTo: collar,
      diameter: 55,
      stroke: 1,
      fill: true,
      color: blue,

      translate: { y: 0, z: 10 },
    });

    function animate() {
      shirt.rotate.y += 0.01;
      shirt.updateRenderGraph();
      // animate next frame
      requestAnimationFrame(animate);
    }

    animate();
  }
}

export default LongSleeveShirt;
