import React from "react";
import "./HeavyBeanie.css";
import Zdog from "zdog";

class HeavyBeanie extends React.Component {
  render() {
    return (
      <canvas className="zdog-canvas-heavy-beanie" width="800" height="500" />
    );
  }

  componentDidMount() {
    const TAU = Zdog.TAU;
    const orange = "#cb4b16";
    const blue = "#073642";

    let hat = new Zdog.Illustration({
      element: ".zdog-canvas-heavy-beanie", // set canvas with selector
      dragRotate: true,
      rotate: { x: TAU / 7, z: TAU / -5, y: TAU / -14 },
      translate: { y: -2 },
      zoom: 4.1,
    });

    let dome = new Zdog.Hemisphere({
      addTo: hat,
      diameter: 80,
      stroke: 20,
      color: orange,
      backface: orange,
      fill: true,
    });

    // headband
    new Zdog.Cylinder({
      addTo: dome,
      diameter: 80,
      stroke: 35,
      length: 10,
      color: blue,
      backface: blue,
      fill: true,
      translate: { z: -10, y: 0 },
    });

    // pompom
    new Zdog.Shape({
      addTo: dome,
      stroke: 25,
      color: blue,
      translate: { z: 55 },
    });

    function animate() {
      hat.rotate.z += 0.01;
      hat.updateRenderGraph();
      // animate next frame
      requestAnimationFrame(animate);
    }

    animate();
  }
}

export default HeavyBeanie;
