import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Forecast from './Forecast';
import Title from './Title';
import reportWebVitals from './reportWebVitals';
import Sport from './Sport';
import Footer from './Footer';

ReactDOM.render(
  <React.StrictMode>
    <div className='main-content'>
    <div id="clothing-category-container">
      <Title />
      <Sport />
    </div>
    <Forecast /> 
    </div>
    <Footer />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
