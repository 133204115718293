import React from "react";
import "./City.css";
import worldDatabase from "iso3166-2-db/i18n/dispute/UN/en";

class City extends React.Component {
  render() {
    return (
      <li className='city-container'>
        <button className='city-button' onClick={this.props.onClick.bind(this, this.props.lat, this.props.lon, this.props.city_name)}>
          {this.props.city_name + ', '}
          {this.getSubdivision(this.props.country_code, this.props.state_code)}
          {this.getCountry(this.props.country_code)}
        </button>
      </li>
    );
  }

  /* Convert Alpha-2 code to full country name */
  getCountry(iso3166_country_code) {
    if (iso3166_country_code in worldDatabase) {
      return worldDatabase[iso3166_country_code].name;
    }
    return iso3166_country_code;
  }

  /* Convert Alpha-2 subdivison code to full province or state name */
  getSubdivision(iso3166_country_code, iso3166_subdivison_code) {
    if (iso3166_country_code in worldDatabase && iso3166_subdivison_code) {

      var state = worldDatabase[iso3166_country_code].regions.find((element) => element.name === iso3166_subdivison_code);
      if (!state){
        console.warn("iso3166 subdivision not found for Country: " + iso3166_country_code + ", Subdivision: " + iso3166_subdivison_code);
      }
      return state === undefined ? '' : state.name + ', ';
    }
    return '';
  }
}

export default City;
