import './Sport.css';

function Sport() {
  return (
    <ul className='sport-list'>
      <li className='sport' id="running"><a /*href="/"*/>Running</a>
        <ul className="dropdown">
          <li className='sport' id="cycling"><a /*href="/"*/>Cycling</a></li>
          <li className='sport' id='hiking'><a /*href="/"*/>Hiking</a></li>
        </ul>
      </li>
    </ul>
  );
}

export default Sport;