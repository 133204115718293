import React from 'react';
import Garment from './Garment';
import './Dresser.css';

class Dresser extends React.Component {

    render() {
        return (
            <div className='dresser-container'>
                <ul className='dresser-list'>
                    {this.getGarments(this.props.temperature)}
                </ul>
            </div>
        );
    }

    getGarments(temperature){
        var garment_count = 1;
        return(
            <span>
                {this.getHatGarment(temperature, garment_count++)}
                {this.getGlovesGarment(temperature, garment_count++)}
                {this.getBaseLayerTopGarment(temperature, garment_count++)}
                {this.getMidLayerTopGarment(temperature, garment_count++)}
                {this.getOuterLayerTopGarment(temperature, garment_count++)}
                {this.getBaseLayerBottomsGarment(temperature, garment_count++)}
                {this.getOuterLayerBottomsGarment(temperature, garment_count++)}
            </span>
        );
    }

    getHatGarment(temperature, order){
        var hat = this.getHat(temperature)
        if (hat){
            return <Garment name={hat.name} description={hat.description} animationOrder={order}></Garment>; // TODO: Get animationOrder working with a count.
        }
    }

    getGlovesGarment(temperature, order){
        var gloves = this.getGloves(temperature)
        if (gloves){
            return <Garment name={gloves.name} description={gloves.description} animationOrder={order}></Garment>;
        }
    }

    getBaseLayerTopGarment(temperature, order){
        var baseLayerTop = this.getBaseLayerTop(temperature)
        if (baseLayerTop){
            return <Garment name={baseLayerTop.name} description={baseLayerTop.description} animationOrder={order}></Garment>;
        }
    }

    getMidLayerTopGarment(temperature, order){
        var midLayerTop = this.getMidLayerTop(temperature)
        if (midLayerTop){
            return <Garment name={midLayerTop.name} description={midLayerTop.description} animationOrder={order}></Garment>;
        }
    }

    getOuterLayerTopGarment(temperature, order){
        var outerLayerTop = this.getOuterLayerTop(temperature)
        if (outerLayerTop){
            return <Garment name={outerLayerTop.name} description={outerLayerTop.description} animationOrder={order}></Garment>;
        }
    }

    getBaseLayerBottomsGarment(temperature, order){
        var baseLayerBottoms = this.getBaseLayerBottoms(temperature)
        if (baseLayerBottoms){
            return <Garment name={baseLayerBottoms.name} description={baseLayerBottoms.description} animationOrder={order}></Garment>;
        }
    }

    getOuterLayerBottomsGarment(temperature, order){
        var outerLayerBottoms = this.getOuterLayerBottoms(temperature)
        if (outerLayerBottoms){
            return <Garment name={outerLayerBottoms.name} description={outerLayerBottoms.description} animationOrder={order}></Garment>;
        }
    }

    getHat(temperature){
        if (temperature > 3 && this.props.isDayTime){
            return {
                name: 'baseball cap',
                description: 'A hat with a visor will offer some protection from the sun.'
            };
        }
        else if (temperature <= 3 && temperature >= -3){
            return {
                name: 'lightweight headband',
                description: 'A thin headband is all it takes to keep your ears warm from the cold and wind.'
            };
        }
        else if (temperature < -3 && temperature >= -12){
            return {
                name: 'lightweight beanie',
                description: 'A thin sythetic or merino wool hat will keep you warm while allowing moisture to escape.'
            };
        }
        else if (temperature < -12){
            return {
                name: 'heavyweight beanie',
                description: 'A thicker fleece or wool hat will prevent heat from escaping from your head during the coldest days.'
            };
        }
    }
    
    getGloves(temperature){
        if (temperature <= 3 && temperature >= -9){
            return {
                name: 'lightweight gloves',
                description: 'A fine layer of polyester or merino wool is all it takes to protect your hands from the cool wind.'
            };
        }
        else if (temperature < -9 && temperature > -18){
            return {
                name: 'midweight gloves or mittens',
                description: 'On colder days, opt for a glove or mitten which is insulated with fleece or wool.'
            };
        } else if (temperature <= -18){
            return {
                name: 'heavyweight gloves',
                description: 'Choose thicker, insulated gloves or mittens which are waterproof during frigid temperatures. As the saying goes: cold hands, warm heart!  Why not have both? '
            };
        }
    }

    // TODO: getNeckWarmer()

    getBaseLayerTop(temperature){
        if (temperature > 23){
            return {
                name: 'tank top',
                description: 'Lets you shed as much heat as possible on the hottest days.  Remember to apply sunscreen to your shoulders.'
            };
        }
        else if (temperature <= 23 && temperature > 8){
            return {
                name: 'short sleeve shirt',
                description: 'Synthetic fabrics are ideal to wick moisture away from your skin and keep you dry.'
            };
        }
        else {
            return {
                name: 'long sleeve shirt',
                description: 'Synthetic fabrics are ideal to wick moisture away from your skin and keep you dry.'
            };
        }
    }

    getMidLayerTop(temperature){
        if ((temperature < -14 && temperature > -18) || temperature <= -26){
            return {
                name: 'lightweight fleece sweater',
                description: 'A fleece layer on cold days will add warmth while allowing moisture to pass through it.'
            };
        }
    }

    getOuterLayerTop(temperature){
        if (temperature < 5 && temperature > -8){
            return {
                name: 'lightweight jacket',
                description: 'A thin shell or raincoat will protect you from the wind and trap a small amount of heat in.'
            };
        }
        else if (temperature <= -8 && temperature > -18){
            return {
                name: 'lightweight insulated jacket',
                description: 'A shell jacket with a small amount of insulation will add extra warmth. Synthetic insulation will expel moisture and stay warm when wet.'
            };
        } else if (temperature <= -18){
            return {
                name: 'heavyweight insulated jacket',
                description: 'A waterproof jacket with thick insulation will protect you from the deep cold.'
            };
        }
    }

    getBaseLayerBottoms(temperature){
        if (temperature >= 8){
            return {
                name: 'shorts',
                description: 'A technical fabric is best.  The inseam is up to you!'
            };
        }
        else if (temperature < 8 && temperature >= -8){
            return {
                name: 'lightweight leggings',
                description: 'A thin synthetic or merino wool legging will add some warmth on cooler runs.  If you need pockets, they can be layered under a pair of shorts.'
            };
        }
        else if (temperature < -8 && temperature > -16){
            return {
                name: 'lightly insulated, wind and water resistant leggings',
                description: 'A legging or shell pant with a thin layer of fleece and a water or wind resistant outer will cut the wind and keep you warm.'
            };
        } else {
            return {
                name: 'heavyweight insulated leggings',
                description: 'A legging with a moderate thickness of fleece insulation will keep those legs toasty.'
            };
        }
    }

    getOuterLayerBottoms(temperature){
        if (temperature < -16){
            return {
                name: 'wind and waterproof shell pants',
                description: 'A shell pant that is both wind and waterproof will shield you from harsh conditions.  A waterproof-breathable membrane such as gore-tex is ideal.'
            };
        }
    }
}

export default Dresser;
