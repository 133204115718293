import React from "react";
import "./Footer.css";
import { ReactComponent as Bitcoin } from "./assets/images/bitcoin.svg";
const RECEIVING_ADDRESS = 'bc1qq20qceufxqrcgsyafzs7xzfphf244p9tzje82n'
const RECEIVING_ADDRESS_LINK = 'bitcoin:' + RECEIVING_ADDRESS;
const LIBREPAY_DONATION_LINK = 'https://liberapay.com/WhatDoIWear/donate';
const LIBREPAY_DONATION_WIDGET_LINK = 'https://liberapay.com/assets/widgets/donate.svg';

class Footer extends React.Component {
    render() {
        return (
            <div className="footer-container">
                <div className="footer">

                    <div className='donation-icons'>
                        <div className='libreapay-badge'>
                            <a href={LIBREPAY_DONATION_LINK} target="_blank" rel='noreferrer '><img alt="Donate using Liberapay" src={LIBREPAY_DONATION_WIDGET_LINK} /></a>
                        </div>
                        <div className="bitcoin-logo-icon">
                            <Bitcoin alt="Bitcoin logo"></Bitcoin>
                        </div>
                        <a href={RECEIVING_ADDRESS_LINK} alt='Donate using Bitcoin' className="bitcoin-logo-text">Bitcoin</a>
                    </div>

                    <a href={RECEIVING_ADDRESS_LINK} className='bitcoin-receiving-address'>{RECEIVING_ADDRESS}</a>
                    <div className="donation-message">
                        Please consider donating to keep my server running and ad-free :)&nbsp; &nbsp;
                        <a className='contact-link' href='mailto:&#115;&#117;&#112;&#112;&#111;&#114;&#116;&#064;&#119;&#104;&#097;&#116;&#100;&#111;&#105;&#119;&#101;&#097;&#114;&#046;&#099;&#097;?subject=Support%20or%20Suggestions&body=Thank%20you%20for%20reaching%20out!%20%F0%9F%91%89%F0%9F%98%8E%F0%9F%91%89%0D%0A%0D%0AI%20have%20attached%20some%20info%20to%20help%20with%20troubleshooting%20any%20bugs.%0D%0ADevice%3A%20%3CPlease%20enter%20your%20device%2C%20i.e.%20iPhone%3E%0D%0ABrowser%3A%20%3CPlease%20enter%20your%20web%20browser%2C%20i.e.%20Chrome%3E%0D%0ALocation%3A%20MyCity%0D%0ATimestamp%3A%20MyTime%0D%0ATimezone%3A%20MyTimeZone%0D%0ATemperature%3A%20MyTemp%0D%0AWindSpeed%3A%20MyWindSpeed%0D%0A%0D%0A--------------------%0D%0A%0D%0A'>Contact</a>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;
