import React from "react";
import "./GpsIcon.css";
import { ReactComponent as Location } from "./assets/images/location.svg";

var CustomTag = Location;

// TODO: Make clicking on this icon a) request permission to access location if not already granted, and b) retrieve current lat/lon.
class GpsIcon extends React.Component {
  render() {
    return (
      <CustomTag
        className={
          "location-icon " +
          this.props.status +
          (this.props.status === "SEARCHING" ? " flicker" : "")
        }
      />
    );
  }
}

export const GPS_STATUS = {
  NO_SIGNAL: "NO_SIGNAL",
  SEARCHING: "SEARCHING",
  LOCATION_FOUND: "LOCATION_FOUND",
};

export default GpsIcon;
